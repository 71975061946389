<template>
	<b-row>
		<b-col cols="12">
			<div class="sign">F1</div>
		</b-col>
	</b-row>
</template>

<script>
	export default {
		name: "index",
	};
</script>

<style lang="scss" scoped>
	.app {
		position: relative;

		.sign {
			position: absolute;
			top: 0;
			left: 0;
			width: 6rem;
			height: 3rem;
			background: #8bcdea;
			text-align: center;
			line-height: 3rem;
			color: #ffffff;
			font-size: 2rem;
			font-weight: bolder;
			border-radius: 0.2rem;
		}
	}
</style>
